import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpServiceService, ErrorServiceService, GetterSetterMembers } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class AppCommonService {
  public login: Subject<any> = new Subject();
  private getterSetterMember = new GetterSetterMembers();
  private routingData = [
    { order: 1, moduleName: 'Dashboard', routerLink: '/dashboard' },
    { order: 2, moduleName: 'Visitors Entry', routerLink: '/visitors-list' },
    { order: 3, moduleName: 'Reports', routerLink: '/reports-list' },
    { order: 4, moduleName: 'Registers', routerLink: '/registers' },
    // { order: 5, moduleName: 'Users', routerLink: '/my-assets' },
    { order: 6, moduleName: 'Site', routerLink: '/site' },
    { order: 7, moduleName: 'Users', routerLink: '/users' },
    { order: 8, moduleName: 'Logout', routerLink: '/login' }
  ];

  constructor(
    private httpService: HttpServiceService,
    private errService: ErrorServiceService) { }

  /* get all the routing/module details */
  getRoutingData() {
    return this.routingData;
  }


  get(url: any): Observable<any> {
    // this.sharedService.display(true);
    return this.httpService.get(url).pipe(
      map((res: Response) => {
        // this.sharedService.display(false);
        return res
      }),
      catchError(error => {
        // this.sharedService.display(false);
        this.errorHandler(error);
        return throwError(error)
      })
    );
  }

  /**
   * @method - to send the data
   * @param url - api name
   * @param data - login credentials
   * @param showLoader - flag to set the loader
   */
  post(url: any, data: any, showLoader?: boolean): Observable<any> {
    return this.httpService.post(url, data)
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError(error => {
          this.errorHandler(error);
          return throwError(error);
        })
      );
  }

  /**
 * @method - handle the error response
 * @param error - handle error obtained through api response
 */
  errorHandler(error: any) {
    throwError(this.errService.handleError(error));
  }

  /* set the 'value' to the 'category' being passed */
  setData(category: string, value: string) {
    this.getterSetterMember[category] = value;
  }

  /* get the value of the 'category' being passed */
  getData(category: string,): string {
    return this.getterSetterMember[category];
  }

}
