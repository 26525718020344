<div class="row">
  <div class="col-md-12">
    <nav *ngIf="modules">
      <!-- <h2 class="text-center">DoR</h2>
      <p>Digitization of Registers</p> -->
      <img src="../../../assets/images/dor-dashboard-color.svg" alt="dor_img" class="dor-img">
      <ul class="sidebar-nav">
        <li *ngFor="let module of modules" class="sidebar-option">
          <a [ngClass]="{'active' : checkActiveRoute(module.routerLink)}"
            routerLink="{{module.routerLink}}">{{module.moduleName}}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>