import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './app-common/layout/layout.component';
import { AuthGuard } from './keycloak/app.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/app-common/app-common.module').then(
        (m) => m.AppCommonModule
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dor/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'visitors-list',
        loadChildren: () =>
          import('./dor/visitors-entry/visitors-entry.module').then(
            (m) => m.VisitorsEntryModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'reports-list',
        loadChildren: () =>
          import('./dor/reports/reports.module').then((m) => m.ReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'site',
        loadChildren: () =>
          import('./dor/site/site.module').then((m) => m.SiteModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./dor/users/users.module').then((m) => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'registers',
        loadChildren: () =>
          import('./dor/registers/registers.module').then(
            (m) => m.RegistersModule
          ),
        canActivate: [AuthGuard]
      },
      { path: '**', redirectTo: 'dashboard' } /*  */,
    ],
  },
  {
    path: '**',
    loadChildren: () =>
      import('src/app/app-common/app-common.module').then(
        (m) => m.AppCommonModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
