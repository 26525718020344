<div class="row no-gutter">
    <div class="col-md-2 p-0 sidebar">
        <app-side-bar></app-side-bar>
    </div>
    <div class="col-md-10 p-0 layout">
        <div class="row">
            <div class="col-md-12">
                <app-header></app-header>
            </div>
        </div>
        <div class="row layout-content">
            <div class="col-md-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>