import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
        keycloak.init({
            config: {
                url: process.env.KEYCLOAK_URL,
                realm: process.env.KEYCLOAK_REALM,
                clientId: process.env.KEYCLOAK_CLIENT
            },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer',
            bearerExcludedUrls: ['/assets'],
            initOptions: {
                checkLoginIframe: true,
                checkLoginIframeInterval: 30
                // onLoad: 'check-sso',
                // silentCheckSsoRedirectUri:
                //     window.location.origin + '/assets/silent-check-sso.html',
            }
        });
}