<header>
    <div class="row no-gutter app-header">
        <div class="col-12">
            <div class="row no-gutter">
                <div class="col-6 header-menu text-left" *ngIf="breadCrumbs">
                    <div class="user-name cursor d-flex gap-1" [routerLink]="routingUrl" routerLinkActive="router-link-active">
                        <img class="breadcrumbs" src="../../../assets/images/back.svg" alt="">
                        Back to Registers
                        <div class="clearfix"></div>
                    </div>
                    <div class="user-name">
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="col-6 header-menu text-left" *ngIf="!breadCrumbs">
                    <div class="user-name title">
                        Digitization of Registers 
                        <div class="clearfix"></div>
                    </div>
                    <div class="user-name">
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div
                    class="col-6 header-menu d-flex flex-column align-items-end">
                    <div class="user-name">
                        {{fullName || 'Name'}}
                        <div class="clearfix"></div>
                    </div>
                    <div class="user-name">
                        ({{loggedInUserName || 'role'}})
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</header>