import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorServiceService {

  constructor(private router: Router) { }

  handleError(error: any) {

    if (error.status === 401) {
      this.router.navigate(['login']);
      // window.location.reload();
    }
  }
}
