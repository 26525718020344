import { Component, OnInit } from '@angular/core';
import { AppCommonService } from '../app-common.service';
import { Router } from '@angular/router';
import { SessionStorage } from 'ngx-webstorage';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  public modules: Array<any> = [];
  @SessionStorage('auth') public user;

  constructor(
    private appCommonService: AppCommonService,
    private router: Router) {

    this.appCommonService.login.subscribe((res: boolean) => {
      if (res) {
        this.getRoutingModule()
      }
    });
  }

  ngOnInit(): void {
    this.getRoutingModule()
  }


  getRoutingModule() {
    this.modules = this.appCommonService.getRoutingData();
    if (this.user.userDetails.role == 'security') {
      this.modules = this.modules.filter(ele => ele.moduleName != 'Site' && ele.moduleName != 'Users' && ele.moduleName != 'Reports');
    }
    else if (this.user.userDetails.role == 'admin')
    this.modules = this.modules.filter(ele => ele.moduleName != 'Site');
  }

  /**
 * @method - to make the clicked side menu active
 * @param identifier - the side menu which is clicked
 */
  checkActiveRoute(identifier: string) {
    const active = this.router.url.indexOf(identifier) === -1 ? false : true;
    return active;
  }
}